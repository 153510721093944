<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row dense>
      <v-col cols="12" class="ma-0 pa-0">
        <v-btn
          color="color3"
          text x-small
          :loading="loadingLogs"
          @click.stop="loadLogs"
        >Check Status</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :items="teams"
          :headers="headers"
          :loading="division.loading"
          :options.sync="page"
          :search="search"
          class="elevation-1"
          hide-default-footer
          show-select
          v-model="selected"
        >
          <template v-slot:loading>
            <v-progress-linear color="color3" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.unix`]="{item}">
            {{item.unix | unixToLocalDt}}
          </template>
          <template v-slot:[`item.status`]="{item}">
            <v-skeleton-loader
              v-if="loadingLogs"
              type="text"
            ></v-skeleton-loader>
            <div v-else>{{getLastStatus(item.id)}}</div>
          </template>
          <template v-slot:[`item.players`]="{ item }">
            <div>
              <div class="noWrap" v-for="p in item.players" :key="p.id">
                {{p.name}}{{division.showCommit && p.commitAbbr ? ` (${p.commitAbbr})` : ''}}
                <i class="fas fa-heart ml-2" v-if="p.isBookmarked(user)"></i>
              </div>
            </div>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-tooltip left>
              <span>Manage Team</span>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="color3 color3Text--text"
                  small icon
                  :to="{name: 'division-team', params: {teamId: item.id}}"
                >
                  <v-icon small>fas fa-caret-right</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="selected.length">
        <v-row dense>
          <v-col cols="12" class="mt-0 pt-0">
            <v-btn color="color3" text small @click.stop="manage = !manage">Manage</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <v-dialog
      v-if="selected.length"
      v-model="manage"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Waitlist Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color1 color1Text--text" small fab @click.stop="manage = false" v-if="false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="subheading">
            You have {{selected.length}} team{{selected.length > 1 ? 's' : ''}} selected
          </div>
          <div class="subheading">
            Are these teams making it in or should I withdraw them?
          </div>
          <v-radio-group v-model="inOrOut" color="color3">
            <v-radio label="Move these teams in" value="in" color="color3"></v-radio>
            <v-radio label="These teams are not making it in - coming soon" value="out" disabled="" color="color3" v-if="false"></v-radio>
          </v-radio-group>
          <v-expand-transition>
            <div v-if="inOrOut">
              <v-checkbox
                label="Notify by email"
                v-model="notify"
                hide-details
                class="mt-0"
                color="color3"
              ></v-checkbox>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="inOrOut">
              <v-checkbox
                label="Charge unpaid teams before moving in"
                v-model="charge"
                hide-details
                class="mt-0"
                color="color3"
              ></v-checkbox>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="inOrOut">
              <v-checkbox
                label="Auto email teams that do not clear and ask them to pay"
                v-model="autoEmailBounces"
                hide-details
                class="mt-0"
                color="color3"
              ></v-checkbox>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              v-if="inOrOut"
              small fab
              color="color3 color3Text--text"
              @click.stop="onSaveClick"
              :loading="loading"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-btn
            small fab
            color="error white--text"
            @click.stop="manage = false"
            :disabled="loading"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { firstBy } from 'thenby'

export default {
  props: ['division', 'search', 'columns', 'tournamentId', 'showPlayers'],
  data () {
    return {
      manage: false,
      selected: [],
      inOrOut: 'in',
      notify: true,
      charge: true,
      page: { itemsPerPage: -1, sortBy: [this.division.waitlistSort] },
      loading: false,
      loadingLogs: false,
      logs: null,
      autoEmailBounces: true
    }
  },
  computed: {
    teams () {
      return this.division.waitlistTeams
    },
    headers () {
      const headers = [
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        { text: 'Last Status', value: 'status', align: 'left', sortable: true },
        { text: 'Date Registered', value: 'unix', align: 'left', sortable: true }
      ]
      if (this.showPlayers) {
        headers.splice(1, 0, { text: 'Players', value: 'players', align: 'left', sortable: false })
      }
      this.columns.forEach(c => {
        headers.push({ text: c.header, value: c.value, align: 'left', sortable: true })
      })
      headers.push({ text: '', value: 'action', align: 'right', sortable: false })
      return headers
    },
    dto () {
      return this.inOrOut && {
        teamIds: this.selected.map(m => m.id),
        in: this.inOrOut === 'in',
        notify: this.notify,
        charge: this.charge,
        autoEmailBounces: this.autoEmailBounces
      }
    }
  },
  methods: {
    getStatus (teamId) {
      return this.logs && this.logs.filter(f => f.teamId === teamId).sort(firstBy('unix', -1))
    },
    getLastStatus (teamId) {
      const stats = this.getStatus(teamId)
      return stats && stats.length ? stats[0].note : ''
    },
    onSaveClick () {
      if (!this.dto) return
      this.loading = true
      this.$VBL.waitlist(this.dto)
        .then(r => {
          this.manage = false
          this.selected = []
          this.loadLogs()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    loadLogs () {
      this.loadingLogs = true
      this.$VBL.waitlistLogs(this.tournamentId)
        .then(r => {
          this.logs = r.data && r.data.map(m => {
            m.dt = moment(m.dtCreated)
            m.unix = m.dt.unix()
            return m
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loadingLogs = false })
    }
  },
  watch: {
    manage: function (val) {
      if (!val) {
        this.inOrOut = null
        this.notify = true
        this.refund = false
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadLogs()
  }
}
</script>

<style scoped>
  .noWrap {
    white-space: nowrap;
  }
  .deleted {
    text-decoration: line-through
  }
</style>
